import { PageContainer } from '@loveholidays/design-system';
import React, { forwardRef } from 'react';

import { ClassNameProps } from '@ComponentProps';

type SectionContainerProps = ClassNameProps & {
  children: React.ReactNode;
};

export const SectionContainer = forwardRef<HTMLDivElement, SectionContainerProps>(
  ({ 'data-id': dataId, className, children }, ref) => (
    <section
      ref={ref}
      data-id={dataId}
      className={className}
      sx={{
        backgroundColor: 'backgroundLightsubtle',
        paddingTop: 'l',
        paddingBottom: 'l',
      }}
    >
      <PageContainer>{children}</PageContainer>
    </section>
  ),
);
